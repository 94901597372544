body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
.react-pdf__Page__canvas{
  margin-bottom: 25px;
}

/* .pdf-div{
  width: 1200px; 
  height: 700px; 
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px; 
} */


.pdf-div {
  width: 100%; /* Make it responsive */
  max-width: 1200px; /* Limit max width */
  height: 100%; /* Let it grow */
  max-height: 700px; /* Restrict max height */
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
}
