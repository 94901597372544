
.flex-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

.center {
    text-align: center;
}

.p-x-1 {
    padding: 0 1rem;
}

.m-x-1 {
    margin: 0 1rem;
}

.m-t-1 {
    margin-top: 1rem;
}

.big-font {
    font-size: 3rem;
}