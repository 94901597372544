.team-view {
    display: flex;
}


.team-grid {
    width: 66.67%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.available-players {
    width: 33.33%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
}

.team-container {
    padding: 6px;
    margin: 15px !important;
    background-color: #f9fafb;
    border-radius: 10px;
    box-shadow: rgba(226, 226, 226, 0.2) 0px 2px 8px 0px;
    display: flex;
    flex-direction: column;
}

.team-header-main {
    display: flex;
    align-items: center;
    width: 100%; /* Use full width */
}

.team-header {
    display: flex;
    align-items: center;
    flex-grow: 1; /* Allow team-header to take up available space */
}

.team-header-buttons {
    display: flex;
    flex-direction: row;
    margin-left: auto; /* Pushes buttons to the right */
    gap: 8px; /* Adds space between the buttons */
}

.team-header Button {
    padding: 5px; /* Keep padding to maintain size */
    width: auto; /* Set width to auto to prevent shrinking */
    height: auto; /* Set height to auto to prevent shrinking */
}



.team-title {
    padding: 12px;
    font-size: 20px;
}

.team-list {
    padding: 6px;
    transition: background-color 0.2s ease;
    flex-grow: 1;
    min-height: 50vh;
}

.player-container {
    border-radius: 8px;
    padding: 15px 12px;
    margin: 8px 0px;
    transition: background-color 0.2s ease;
    background-color: #f9fafb;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.player-reserve-active {
    color: #44aae8 !important;
}

.player-no-reserve {
    color: #cfcfcf !important;
}