.event-popper{
    width: 300px;
    padding: 15px;
    border-radius: 18px;
    position: relative;
}

.event-popper-header{
    font-size: 22px;
    position: relative;
    padding-top: 12px;
    padding-bottom: 8px;
}

.delete-button{
    position: absolute;
    right: 0px;
    top: 0px;
    color: #E57373;
    cursor: pointer;
    scale: 1.1;
}

.event-popper-header::before{
    background-color: #009be5;
    position: absolute;
    width: 30%;
    top: 0px;
    left: -2px;
    height: 5px;
    content: "";
    border-radius: 8px;
}

.event-popper-body p{
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 4px;
}

.context-icon{
    position: absolute;
    right: 0px;
    top: -13px;
}

.event-popper-context-menu{
    position: absolute;
    right: 12px;
    top: 30px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    padding: 6px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 110px;
    z-index: 100;
    transition: all 0.12s ease-in-out;
}

.event-popper-context-menu button:nth-child(1){
    margin-bottom: 2px;
}

.event-popper-context-menu button:nth-child(2){
    margin-top: 2px;
}

.context-item{
    cursor: pointer;
    padding: 3px 5px;
    width: 100%;
    border-radius: 4px;
    text-align: left;
}

.context-item:hover{
    background-color: #f5f5f5;
    ;
}

.context-item .item-text{
    padding-left: 5px;
}

.context-item .edit-icon{
    color: #4e4e4e;
}

.context-item .delete-icon{
    color: #ca5050;
}

.invisible{
    opacity: 0;
}

.event-create{
    padding: 15px;
}

.event-create-header{
    font-size: 22px;
    position: relative;
    padding-top: 12px;
    padding-bottom: 8px;
}

.event-create-header::before{
    background-color: #009be5;
    position: absolute;
    width: 30%;
    top: 0px;
    left: -2px;
    height: 5px;
    content: "";
    border-radius: 8px;
}

.event-create-body{
    margin-top: 15px;
    display: flex;
    flex-direction: column;
}

.time-input{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 20px;
}

.event-name{
    padding-bottom: 20px;
    width: 100%;
}

.spanner{
    margin: 0px 10px; 
    width: 20px;
    background-color: #727272;
    height: 3px;
    border-radius: 10px;
}

.event-create-footer{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 20px;
}

.event-popper-value{
    display: flex;
    align-items: center;
    padding: 2px 0px;
}

.event-popper-value .icon{
    color: #727272;
}

.event-popper-value span{
    padding-left: 5px;
}